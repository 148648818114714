import React, { lazy } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';

import Navigation from './common/components/Navigation/Navigation';
import Footer from './common/components/Footer/Footer';
import { LoadingSpinner } from './common/components/UIElements/loadingAnimations';

/**
 * Lazy loading of components
 */
const Home = lazy(() => import('./pages/Home/Home'));
const NotFound = lazy(() => import('./pages/404/404'));


const App = () => {
  return (
    <React.Fragment>
      <Router>
        <Navigation />
        <React.Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate replace to="/404" />} />
          </Routes>
        </React.Suspense>
        <Footer />
      </Router>
    </React.Fragment>
  );
};

export default App;
