import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Stack
} from 'react-bootstrap';

import useHttpClient from '../../hooks/http-hook';
import './Footer.scss';

const Footer = () => {
  const [visits, setVisits] = useState(0);
  const { isLoading, error, sendRequest } = useHttpClient();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest(
          `https://api.countapi.xyz/hit/manish-thakur.in/${process.env.REACT_APP_COUNTAPI_KEY}`
        );
        setVisits(responseData.value);
      } catch (err) {}
    };
    fetchData();
  }, [sendRequest]);

  return (
    <div id="footer">
      <footer>
        <Row>
          <Col sm>
            <h1>Let's Connect</h1>
            <h6>
              Visits:{' '}
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Times Viewed</Tooltip>}
              >
                <span className="visits">
                  {isLoading ? <Spinner animation="grow" size="sm" /> : visits}
                  {error && <span>{error}</span>}
                </span>
              </OverlayTrigger>
            </h6>
          </Col>
        </Row>
      </footer>
      <Stack className="copyright" direction="horizontal" gap={5}>
        <p> &copy; {new Date().getFullYear()} Manish Thakur</p>
        <p className="ms-auto">Made with 💖!</p>
        <p>v1.0.0</p>
      </Stack>
    </div>
  );
};

export default Footer;
